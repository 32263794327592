






import Vue from 'vue'

export default Vue.extend({
  name: 'Select',
  props: {
    name: {
      type: String,
      default: ''
    },
    values: {
      type: Array,
      required: true
    },
    index: {
      type: Number
    }
  },
  data: function () {
    return {
      selectedIndex: undefined as number | undefined
    }
  },
  methods: {
    onChange (e: Event) {
      this.$emit('onChange', this.selectedIndex)
    }
  },
  watch: {
    index: function () {
      if (!isNaN(this.index)) {
        this.selectedIndex = this.index
      }
    }
  }
})






import Vue from 'vue'

export default Vue.extend({
  name: 'SmallButton',
  props: {
    label: String
  },
  methods: {
    onclick (e: Event) {
      this.$emit('click', e)
    }
  }
})






























































import Vue from 'vue'
import SmallButton from '@/components/SmallButton.vue'
import Select from '@/components/Select.vue'
import Receipt from '@/models/Receipt'
import API from '@/utils/API'

const pageSize = 5

export default Vue.extend({
  name: 'ReceiptList',
  components: {
    Select,
    SmallButton
  },
  data: function () {
    return {
      username: '',
      yearRange: [2000, 2001, 2002],
      selectedIndexOfYear: undefined as number|undefined,
      monthRange: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Auguest', 'September', 'October', 'November', 'December'],
      selectedIndexOfMonth: undefined as number|undefined,
      list: [] as Receipt[],
      pageIndex: 0,
      pageAmount: 1,
      errorMessage: ''
    }
  },
  methods: {
    showList () {
      const begin = this.pageIndex * pageSize
      const end = begin + pageSize
      return this.list.slice(begin, end)
    },
    onMonthChange (index: number) {
      this.selectedIndexOfMonth = index
      this.refresh()
    },
    onYearChange (index: number) {
      this.selectedIndexOfYear = index
      this.refresh()
    },
    onSelectReceipt (item: Receipt) {
      console.log(item)
      this.$router.push({
        name: 'Receipt Detail',
        params: {
          id: item.id
        }
      })
    },
    onSeletPage (newPage: number) {
      this.pageIndex = Math.max(0, Math.min(this.pageAmount - 1, newPage))
    },
    refresh () {
      let year
      if (this.selectedIndexOfYear !== undefined) {
        year = this.yearRange[0] + this.selectedIndexOfYear
      }
      let month
      if (this.selectedIndexOfMonth !== undefined) {
        month = this.selectedIndexOfMonth + 1
      }
      this.errorMessage = ''
      API.getReceiptList(year, month).then(list => {
        this.list = list
        this.pageAmount = Math.ceil(list.length / pageSize)
        console.log(list)
      }).catch(e => {
        this.errorMessage = e
      })
    }
  },
  mounted () {
    const now = new Date()
    const past = 5
    this.yearRange = Array.from({ length: past }, (v, k) => k + now.getFullYear() - past + 1)
    this.selectedIndexOfYear = this.yearRange.length - 1
    this.selectedIndexOfMonth = now.getMonth()
    this.refresh()
  }
})
